$main-color: #c7081b;
$secondary-color: #8d9199;
$light-color: #d9d9d9;
$dark-color: #000000;

/*** GENERAL ***/

.bg-dark {
  background-color: #000000;
  --bs-bg-opacity: 0.8;
}

section {
  padding-top: 100px;
  padding-bottom: 100px;

  &.no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.no-padding-top {
    padding-top: 0;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }
}

.section-white {
  background-color: #ffffff;

  h2 {
    color: $main-color;
    font-weight: 600;
  }

  h5 {
    color: $dark-color;
    font-weight: 300;
    margin-bottom: 50px;
  }
}

.section-light {
  background-color: #f0f0f0;

  h2 {
    color: $main-color;
    font-weight: 600;
  }

  h5 {
    color: $dark-color;
    font-weight: 300;
    margin-bottom: 50px;
  }
}

.section-dark {
  background-color: $dark-color;

  h2 {
    color: $main-color;
    font-weight: 600;
  }

  h3 {
    color: $light-color;
    font-weight: 300;
  }

  h5,
  h6 {
    color: $light-color;
    font-weight: 300;
    margin-bottom: 50px;
  }
}

.link {
  text-decoration: none;

  .link-light {
    color: $light-color;
  }

  .link-dark {
    color: $dark-color;
  }

  :hover {
    color: $main-color;
  }
}

.transparent {
  background: none;
}

.text-justified {
  text-align: justify;
}

.text-centered {
  text-align: center;
}

.text-link {
  color: $main-color;
  font-weight: 600;
}

.clickable:hover {
  cursor: pointer;
  color: $main-color;
}

/*** NAVBAR ***/

.top-menu {
  width: 100%;
  color: $light-color;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

.top-menu--language,
.top-menu--location,
.top-menu--phone {
  display: none;
}

nav.navbar {
  background-color: rgba(0, 0, 0, 0.8);
}

/*** HEADER ***/

#header-banner,
.header-secondary {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/bg-banner-02.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem 1rem;

  h1 {
    color: #ffffff;
    font-weight: bold;
    font-size: 36px;
  }

  h2 {
    color: $light-color;
  }

  h4 {
    color: $main-color;
  }
}

.header-secondary {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/bg-banner-01.jpg");
  justify-content: center;
  align-items: center;
  height: 400px;

  h1 {
    font-size: 48px;
  }

  h2 {
    color: $light-color;
    opacity: 0.75;
  }
}

/*** ANIMATED ARROW ***/

.downArrow {
  position: fixed;
  bottom: 5%;
  left: 90%;

  i {
    width: 40px;
    height: 40px;
    color: #ffffff;
  }
}

.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

/*** SECTION - OFFERS ***/

#main-offers,
#booking-form,
#travel-form,
#personal-form,
#airline-form,
#payment-form,
#stepper-section {
  text-align: center;
}

/*** STEPPER ***/
#stepper-section {
  padding-top: 50px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      font-size: 1.5rem;
      line-height: 40px;
      font-weight: 600;
      padding: 0px 15px;
      height: 40px;
      text-align: center;
      vertical-align: center;
      justify-content: center;
      align-items: center;
      font-kerning: none;
    }

    .step-icon {
      color: #000000;
      background-color: rgb(210, 210, 210);
      border-radius: 50%;

      &.active {
        color: #ffffff;
        background-color: $main-color;
      }
    }
  }
}

/*** SWIPER ***/

.swiper-slide {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 50px;
  padding: 1rem;
  height: 300px !important;

  .vehicles-card--info {
    text-align: left;

    h6 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    span {
      font-size: 14px;
      margin-right: 14px;
    }

    img {
      height: 20px;
      margin-right: 5px;
      opacity: 0.6;
    }
  }

  .vehicles-card--price {
    text-align: right;

    h4::before {
      content: "$";
      font-size: 10px;
    }

    h4 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 0;
    }

    span {
      font-size: 10px;
    }
  }
}

.swiper-pagination {
  margin-top: 20px;
}

.swiper-pagination-bullet-active {
  background-color: $main-color !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: $main-color !important;
}

.swiper-slide.sedan {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/sedan/sedan-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.suv {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/suv/suv-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.limo-chrysler {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/limo-chrysler/limo-chrysler-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.limo-mkt {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/limo-mkt/limo-mkt-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.limo-cadillac {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/limo-cadillac/limo-cadillac-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.limo-hummer {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/limo-hummer/limo-hummer-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.party-bus-18 {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/party-bus-18/party-bus-18-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.party-bus-28 {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/party-bus-28/party-bus-28-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.party-bus-44 {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/party-bus-44/party-bus-44-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.sprinter {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/sprinter/sprinter-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.sprinter-party {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/sprinter-party/sprinter-party-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.coach-bus-22 {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/coach-bus-22/coach-bus-22-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.coach-bus-35 {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/coach-bus-35/coach-bus-35-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-slide.coach-bus-56 {
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    url("assets/vehicles/coach-bus-56/coach-bus-56-01.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

/*** FORMS ***/

.btn-group {
  button {
    background-color: #ffffff;
  }
}

.form-check-input:checked {
  background-color: $main-color;
  border-color: $main-color;
}

/*** SECTION STATISTICS ***/

#statistics {
  background-image: url("assets/bg-promo-city-red.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $main-color;

  padding-bottom: 0;

  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #ffffff;
    text-align: center;
    padding-bottom: 30px;
  }

  h3 {
    font-weight: bold;
    font-size: 2em;
  }

  .icon-statistics {
    max-height: 50px;
    margin: 0 auto 30px;
  }
}

/*** SECTION REVIEWS ***/

#reviews {
  text-align: center;

  div[class^="Classic__AuthorName"] {
    text-align: initial;
  }
}

/*** SECTION PROMO ***/

#promo {
  text-align: center;
  position: relative;
  // padding-top: 0 !important;
}

video {
  position: relative !important;
  width: 100%;
  z-index: -10;
}

/*** SECTION FOOTER ***/

#footer {
  background-color: #000000;
  color: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 0.8em;
  line-height: 1.5em;

  img {
    width: 50px;
    height: 50px;
    margin: 0 10px 10px;
    padding: 8px;
    background-color: $secondary-color;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    &:hover {
      cursor: pointer;
      background-color: $main-color;
    }
  }

  .footer-icon-area {
    margin: 20px 0 40px;
  }

  .footer-bottom-area {
    border-top: solid 1px $secondary-color;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) {
  /*** NAVBAR ***/

  .top-menu--language,
  .top-menu--location,
  .top-menu--phone {
    display: block;
  }

  .top-menu {
    display: block;

    .container {
      display: flex;
      justify-content: space-between;
      padding: 8px 10px;
    }

    img {
      height: 1rem;
      margin-right: 10px;
    }
  }

  nav.navbar,
  a.navbar-brand {
    padding: 0;
    flex-wrap: wrap;
  }

  .navbar-nav a {
    height: 50px;
    line-height: 34px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  #navbarNav {
    justify-content: flex-end;
  }

  #navbarNav li:hover,
  #navbarNav .active {
    background-color: $main-color;
  }

  /*** HEADER ***/

  #header-banner {
    background: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1)
      ),
      url("assets/bg-banner-01.jpg");
    background-position: center bottom;
    background-size: cover;
    justify-content: center;
    align-items: center;
    max-height: 800px;

    h1 {
      font-size: 48px;
    }
  }

  .header-secondary {
    background: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1)
      ),
      url("assets/bg-banner-01.jpg");
    justify-content: center;
    align-items: center;
    height: 400px;

    h1 {
      font-size: 48px;
    }
  }

  /*** ANIMATED ARROW ***/

  .downArrow {
    bottom: 5%;
    left: 50%;
  }

  /*** STATISTICS ***/

  #statistics {
    background-position: center top;

    h3 {
      font-size: 2.5em;
    }
  }
}

/*** PAGE - TERMS ***/

#terms-conditions {
  h2 {
    margin-top: 50px;
  }

  h5 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
  }

  img {
    width: 200px;
    height: auto;
    margin: 40px auto 0;
    justify-self: center;
    text-align: center;
  }
}
